import { Badge, Col, Container, ImageGallery, PageBanner, Row, Stack, Text } from '@zigurous/forge-react'; // prettier-ignore
import { graphql } from 'gatsby';
import React from 'react';
import { CallToActionButton, Page } from '../../components';
import { trackEvent } from '../../firebase/analytics';
import type { GameData } from '../../types';

interface GameProps {
  data: { game: GameData };
  location: Location;
}

export default function Game({ data, location }: GameProps) {
  const { game } = data;
  const metadata = {
    url: `https://zigurous.com/games/${game.id}`,
    title: `Zigurous • ${game.title}`,
    description: game.description,
    image: game.image.sharp.original.src,
  };
  return (
    <Page className="game" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row className="align-center">
            <Col className="mb-xxl" size={12} md={4}>
              <Text
                as="div"
                className="ml-xxxs"
                marginBottom="xs"
                type="subtitle"
              >
                {game.subtitle}
              </Text>
              <Text as="h1" marginBottom="lg" type="title-lg">
                {game.title}
              </Text>
              {game.badges && game.badges.length > 0 && (
                <Stack align="center" spacing="sm" wrap>
                  {game.badges.map(badge => (
                    <Badge key={badge} color="primary" variant="tint">
                      {badge}
                    </Badge>
                  ))}
                </Stack>
              )}
            </Col>
            <Col>
              <Text className="text-pretty" marginBottom="lg" type="body-lg">
                {game.description}
              </Text>
              {game.callToAction && game.callToActionURL && (
                <Stack align="center" spacing="md" wrap>
                  <CallToActionButton
                    label={game.callToAction}
                    onClick={() => {
                      trackEvent('cta_button', {
                        content_type: 'button',
                        event_category: 'engagement',
                        event_label: game.callToAction,
                        item_id: game.id,
                      });
                    }}
                    url={game.callToActionURL}
                  />
                </Stack>
              )}
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="mt-xxxl" fluid>
        {game.screenshots && (
          <React.Fragment>
            <Text as="h2" marginBottom="lg" type="title">
              Screenshots
            </Text>
            <ImageGallery
              animated
              columns={4}
              images={game.screenshots.map(image => ({
                width: image.sharp.original.width,
                height: image.sharp.original.height,
                src: image.sharp.original.src,
              }))}
              lightboxProps={{ rootElement: '#portal' }}
            />
          </React.Fragment>
        )}
      </Container>
    </Page>
  );
}

export const query = graphql`
  query ($id: String!) {
    game: gamesJson(id: { eq: $id }) {
      badges
      callToAction
      callToActionURL
      description
      id: jsonId
      image {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      screenshots {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      subtitle
      title
    }
  }
`;
